import { Box, Center, Flex, VStack } from "@chakra-ui/react";
import React from "react";
import { COLORS_PRIMARY } from "../shared/opalite_colors";
import { OpaliteButtonStandard } from "../shared/opalite_buttons";
import {
  OpaliteTextHighlighted,
  OpaliteTextNFTLink,
  OpaliteTextStandard,
  OpaliteTextTitle,
} from "../shared/opalite_text";
import { navigate } from "gatsby";

interface EmailTriggerProps {}

const EmailTrigger: React.FC<EmailTriggerProps> = (props) => {
  return (
    <VStack
      padding="1.5rem"
      backgroundColor={COLORS_PRIMARY.BACKGROUND}
      height="100vh"
    >
      <OpaliteTextTitle>
        Connect your wallet to access your NFT's
      </OpaliteTextTitle>
    </VStack>
  );
};

export default EmailTrigger;
