export const COLORS_PRIMARY = {
  BACKGROUND: "#000000",
  BACKGROUND_OPAQUE: "#1e1e1e",
  BACKGROUND_SOCIALS: "#303030",
  MENU_FONT_COLOR: "#fcfcfc",
  FONT: "#fcfcfc",
  FONT_GRAYED_OUT: "#817e7e",
  HEADER: "rgb(0,0,0,0)",
  HIGHLIGHT_SELECTED: "#f7a5f2",
  CONTENT_DISPLAY_BOX_BACKGROUND: "#424242",
  GRID_GRADIENT:
    "`linear-gradient(to top, rgb(39,39,39, 1) 0%, rgb(39,39,39, 0) 100%))`",
  MINTING_IN_PROCESS_BACKGROUND: "#c6f6d6",
  MINTING_IN_PROCESS_BORDER: "#c6f6d6",
  NFT_LINK_TEXT: "rgb(0,0,0,0)",
  NFT_DISPLAY_DESCRIPTION_SECTION: "#515050",
  NFT_DISPLAY_DESCRIPTION_TEXT: "#747474",
  SLIDE_MENU_BACKGROUND: "#1e1e1e",
  SELECTED_SPECIAL_BUTTON: "#80feb2",
  TEXT_LINK: "#1ca55b",
};
