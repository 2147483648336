import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import EmailTrigger from "../components/email_trigger/email_trigger";

function LoadingPage() {
  return (
    <ChakraProvider>
      <EmailTrigger />
    </ChakraProvider>
  );
}

export default LoadingPage;
