import { Text } from "@chakra-ui/react";
import React from "react";
import { COLORS_PRIMARY } from "./opalite_colors";
import { BiCopy } from "react-icons/bi";

interface OpaliteTextProps {
  children: React.ReactNode | string;
  fontWeight?: string | object;
  textAlign?: string | object;
  marginInline?: string | object;
  margin?: string | object;
  marginTop?: string | object;
  marginBottom?: string | object;
  marginLeft?: string | object;
  marginRight?: string | object;
  padding?: string | object;
  paddingTop?: string | object;
  paddingBottom?: string | object;
  paddingLeft?: string | object;
  paddingRight?: string | object;
  fontSize?: string | object;
  color?: string | object;
  width?: string | object;
  borderColor?: string | object;
  borderRadius?: string | object;
}

export const OpaliteTextTitle: React.FC<OpaliteTextProps> = (props) => {
  return (
    <Text
      fontSize="2.5rem"
      fontWeight="bold"
      lineHeight="120%"
      color={COLORS_PRIMARY.FONT}
      {...props}
    >
      {props.children}
    </Text>
  );
};

export const OpaliteTextSubTitle: React.FC<OpaliteTextProps> = (props) => {
  return (
    <Text fontSize="1.35rem" color={COLORS_PRIMARY.FONT} {...props}>
      {props.children}
    </Text>
  );
};

export const OpaliteTextStandard: React.FC<OpaliteTextProps> = (props) => {
  return (
    <Text
      lineHeight="130%"
      fontSize="1rem"
      color={COLORS_PRIMARY.FONT}
      {...props}
    >
      {props.children}
    </Text>
  );
};

export const OpaliteTextHighlighted: React.FC<OpaliteTextProps> = (props) => {
  return (
    <Text
      fontSize=".75rem"
      padding=".25rem"
      paddingBlock=".1rem"
      borderRadius="2px"
      fontWeight="bold"
      backgroundColor={COLORS_PRIMARY.HIGHLIGHT_SELECTED}
      color={COLORS_PRIMARY.FONT}
      {...props}
    >
      {props.children}
    </Text>
  );
};

export const OpaliteTextHeader: React.FC<OpaliteTextProps> = (props) => {
  return (
    <Text
      fontSize="1rem"
      fontWeight="bold"
      color={COLORS_PRIMARY.FONT}
      {...props}
    >
      {props.children}
    </Text>
  );
};

export const OpaliteTextNFTLink: React.FC<OpaliteTextProps> = (props) => {
  return (
    <Text
      fontSize=".75rem"
      padding=".5rem"
      paddingBlock=".25rem"
      borderRadius="10px"
      borderWidth="2px"
      display="flex"
      justifyContent="space-between"
      flexDirection="row"
      borderColor={COLORS_PRIMARY.FONT}
      backgroundColor={COLORS_PRIMARY.NFT_LINK_TEXT}
      color={COLORS_PRIMARY.FONT}
      {...props}
    >
      {props.children}
      <BiCopy onClick={() => navigator.clipboard.writeText(props.children)} />
    </Text>
  );
};
